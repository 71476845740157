import React, {FC, useState} from "react";
import {QuestDataType} from "data/types";
import ButtonPrimary from "../Button/ButtonPrimary";
import Input from "../Input/Input";
import axios from "axios";
import {QuestState} from "../../data/enums";
import xLogo from "../../images/x-logo.webp"
import youtubeLogo from "../../images/logo-youtube.webp"
import {API_URL} from "../../config";
import Badge from "../Badge/Badge";
import {format} from 'date-fns';
import {useNavigate} from "react-router-dom";

export interface CardQuestProps {
  className?: string;
  quest: QuestDataType;
  onComplete: (state: QuestState) => void;
}

const CardQuest: FC<CardQuestProps> = ({
  className = "h-full",
  quest,
  onComplete,
}) => {
  const { id, title, link, xp, image_url, has_code, button_text, state, expires_at, is_daily_quest } = quest;
  const [errorMessageCode, setErrorMessageCode] = useState("")
  const [code, setCode] = useState("")
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()

  let buttonText: string;

  let questPicture
  switch (image_url) {
    case "youtube":
      questPicture = youtubeLogo;
      break;
    case "x":
      questPicture = xLogo;
      break;
    default:
      questPicture = youtubeLogo;
  }

  switch (state) {
    case QuestState.UNCOMPLETED:
      buttonText = button_text;
      break;
    case QuestState.COMPLETED:
      buttonText = "Complété";
      break;
    case QuestState.TO_VERIFY:
      buttonText = "Vérification... (24h max)";
      break;
    case QuestState.X_NOT_LINKED:
      buttonText = "Lier X";
      break;
    default:
      buttonText = "État inconnu";
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const onClickSubmit = async () => {
    setIsLoading(true);

    if (has_code) {
      await completionRequest(
          `${API_URL}/api/v1/quests/${id}/verify_code`,
          QuestState.COMPLETED
      )
    } else if (is_daily_quest) {
      await completionRequest(
          `${API_URL}/api/v1/quests/${id}/daily_quest`,
          QuestState.COMPLETED
      )
    } else if (state === QuestState.X_NOT_LINKED) {
      navigate("/reglages")
    } else {
      //window.open(link, '_blank');
      await completionRequest(
          `${API_URL}/api/v1/quests/${id}/add_to_verify_queue`,
          QuestState.TO_VERIFY
      )
    }
  };

  const completionRequest = async (url: string, stateAfterCompletion: QuestState) => {
    setIsLoading(true);

    try {
      const response = await axios.post(url, {
        code,
      }, {withCredentials: true});
      if (response.status === 200 && response.data.success) {
        onComplete(stateAfterCompletion);
        setErrorMessageCode("")
      } else {
        setErrorMessageCode(response.data.message || "Une erreur est survenue.");
      }
    } catch (error) {
      setErrorMessageCode("Une erreur réseau est survenue.");
    } finally {
      setIsLoading(false);
    }
  }


  return (
    <div
      className={`nc-Card15Podcast relative flex group items-center p-3 rounded-3xl border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-900 ${className}`}
    >
      <div className="w-1/6 flex-shrink-0">
        <a
          href={link}
          target="_blank"
          className="block aspect-w-1 aspect-h-1 relative rounded-full overflow-hidden shadow-lg flex items-center justify-center bg-yellow-500"
        >
          {/*<Image
        className="object-cover w-full h-full"
        src={questPicture}
        fill
        alt={title}
        sizes="100px"
    />*/}
          <div className={`flex flex-col items-center justify-center h-full w-full text-white lg:text-2xl`}>
            <span>{xp}</span>
            <span>xp</span>
          </div>
        </a>
      </div>


      <div className="flex flex-col flex-grow ml-4 mr-5">
        <h2 className={`nc-card-title block font-semibold text-sm sm:text-lg mt-[-6px]`}>
          <Badge
              color="red"
              name={`Expire le ${format(new Date(expires_at), 'dd-MM-yyyy')}`}
              className=""
          />
          <a
            href={link}
            target="_blank"
            className="line-clamp-6"
            title={title}
          >
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </a>
        </h2>
      </div>

      <div className="flex flex-col lg:w-1/6">
        {has_code && state !== QuestState.COMPLETED &&
            <Input
                type="email"
                name="email"
                value={code}
                onChange={handleInputChange}
                placeholder="CODE"
                className="mt-1 mb-1"
                errorMessage={errorMessageCode}
            />
        }
        <ButtonPrimary
            onClick={onClickSubmit}
            disabled={state === QuestState.COMPLETED || state === QuestState.TO_VERIFY}
        >
          {isLoading ? "Chargement..." : buttonText}
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default CardQuest;
