import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {API_URL} from "../../config";

const AdminRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Rediriger vers l'URL de rails_admin sur votre serveur Rails
        window.location.href = `${API_URL}/admin`;
    }, [navigate]);

    return null;
};

export default AdminRedirect;
