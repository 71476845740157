import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {CollectionDataType, StickerType} from "../../data/types";
import axios from "axios";
import NcImage from "../../components/NcImage/NcImage";
import Image from "../../components/Image";
import {STICKER_IMAGE_COLLECTIONS} from "../../data/stickers";
import {COLLECTION_BANNER_IMAGE} from "../../data/collections";
import { useNavigate } from 'react-router-dom';
import {API_URL} from "../../config";

const CollectionStickers = () => {
    const collectionId = Number(useParams<{ collectionId?: string }>().collectionId) ?? 0;

    const [stickers, setStickers] = useState<StickerType[]>([]);
    const [userStickers, setUserStickers] = useState<Record<number, { shiny: number, nonShiny: number }>>({});
    const [collection, setCollection] = useState<CollectionDataType>();
    const navigate = useNavigate();


    const fetchData = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/v1/collections/${collectionId}`);
            setStickers(response.data.stickers);
            setCollection(response.data.collection);

            const userStickersResponse = await axios.get(`${API_URL}/api/v1/users/user_stickers/`, { withCredentials: true });
            const userStickersData = userStickersResponse.data;

            const userStickersMap: Record<number, { shiny: number, nonShiny: number }> = {};
            userStickersData.forEach((userSticker: any) => {
                const id = userSticker.id;
                if (!userStickersMap[id]) userStickersMap[id] = { shiny: 0, nonShiny: 0 };

                if (userSticker.shiny) userStickersMap[id].shiny += 1;
                else userStickersMap[id].nonShiny += 1;
            });
            setUserStickers(userStickersMap);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const separator = (text: string, color: string, points: string) => {
        return (
            <div className="pt-8 pb-3">
                <div className={"flex justify-between"}>
                    <p style={{color: color}}>{text}</p>
                    {parseInt(points, 10) > 0 &&
                      <p style={{color: color}}>{`${points} pts`}</p>
                    }
                </div>
                <hr
                    style={{
                        background: color,
                        color: color,
                        borderColor: color,
                        height: '3px',
                    }}
                />
            </div>
        )
    }

    const fusionStickers = async (stickerID: number) => {
        try {
            const response = await axios.post(`${API_URL}/api/v1/users/fusion_stickers`,
                { sticker_id: stickerID },
                { withCredentials: true });

            if (response.status === 200) {
                console.log("Stickers fusionnés avec succès!", response.data);
                // Vous pouvez effectuer d'autres actions ici, par exemple mettre à jour l'UI
                fetchData();
                return response.data;
            } else {
                console.error("Erreur lors de la fusion des stickers:", response.data);
                return null;
            }
        } catch (error) {
            console.error("Erreur lors de la fusion des stickers:", error);
            // Gérer l'erreur comme vous le souhaitez (par exemple, afficher un message d'erreur à l'utilisateur)
            return null;
        }
    }

    const renderStickerImageOrVideo = (sticker: StickerType) => {
        const isGrayscale = (userStickers[sticker.id]?.nonShiny || 0) + (userStickers[sticker.id]?.shiny || 0) === 0;
        const baseClassName = `absolute inset-0 object-contain ${isGrayscale ? 'grayscale' : ''}`;

        if (sticker.rarity === "légendairee") {
            return (
                <div className="relative w-full h-64 flex items-center">
                    <video className={`${baseClassName} w-full h-full`} loop autoPlay muted>
                        <source src={STICKER_IMAGE_COLLECTIONS[collectionId][sticker.id] || sticker.image_url} type="video/webm" />
                        Votre navigateur ne supporte pas la vidéo.
                    </video>
                    {renderExtraStars()}
                </div>
            );
        }

        return (
            <div>
                <NcImage
                    alt="sticker"
                    containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden z-0"
                    className={baseClassName}
                    src={STICKER_IMAGE_COLLECTIONS[collectionId][sticker.id] || sticker.image_url}
                />
                {renderExtraStars()}
            </div>
        );
    };

    const renderExtraStars = () => {
        return <span><span><span></span></span></span>;
    };

    const renderStickers = (rarity: string, color: string, points: string) => {
        if (stickers?.filter((sticker) => sticker.rarity === rarity).length <= 0) {
            return null;
        }

        return (
            <>
                {separator(rarity.toUpperCase(), color, points)}
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-8">
                    {stickers?.map((sticker) => sticker.rarity === rarity && (
                        <div className="relative group">
                            <div className={`relative group ${userStickers[sticker.id]?.shiny > 0 ? 'shiny' : ''}`}>
                                {renderStickerImageOrVideo(sticker)}
                            </div>
                            {userStickers[sticker.id]?.nonShiny > 0 && (
                                <div className="absolute bottom-0 right-0 p-1">
                                    x{userStickers[sticker.id]?.nonShiny}
                                </div>
                            )}
                            {userStickers[sticker.id]?.shiny > 0 && (
                                <div className="absolute bottom-0 left-0 p-1">
                                    x{userStickers[sticker.id]?.shiny}🌟
                                </div>
                            )}
                            {/*{(userStickers[sticker.id]?.nonShiny || 0) >= 3 && (*/}
                            {/*    <ButtonPrimary*/}
                            {/*        className="absolute bottom-0 left-1/2 transform -translate-x-1/2"*/}
                            {/*        onClick={() => fusionStickers(sticker.id)}*/}
                            {/*    >*/}
                            {/*        Fusionner*/}
                            {/*    </ButtonPrimary>*/}
                            {/*)}*/}
                        </div>
                    ))}
                </div>
            </>
        )
    };

    return (
        <div className="container ">
            <div className="absolute top-4 left-4 z-50">
                <button onClick={() => navigate("/mon-compte", { state: { tabActive: 1 } })} className="text-2xl font-bold">
                    ←
                </button>
            </div>

            <div className={`nc-SectionGridAuthorBox relative py-3 lg:py-8`}>
                <div className={`nc-PageArchive`}>
                    {/* HEADER */}
                    <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
                        <div className="relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 rounded-3xl md:rounded-[40px] overflow-hidden z-0">
                            <Image
                                alt="archive"
                                fill
                                src={collectionId ? COLLECTION_BANNER_IMAGE[collectionId as unknown as keyof typeof COLLECTION_BANNER_IMAGE] : ""}
                                className="object-cover w-full h-full rounded-3xl md:rounded-[40px]"
                                sizes="(max-width: 1280px) 100vw, 1536px"
                            />
                            <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
                                <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
                                    {collection?.name}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                {renderStickers("spécial", "#ef4444", "0")}
                {renderStickers("légendaire", "#eab308", "150")}
                {renderStickers("épique", "#a855f7", "60")}
                {renderStickers("rare", "#3b82f6", "25")}
                {renderStickers("commun", "#64748b", "10")}
            </div>
        </div>
    )
}

export default CollectionStickers