import MyRoutes from "./routers";
import {useEffect, useState} from "react";
import React from "react";
import axios from "axios";
import {log} from "util";
import { createContext } from "react";
import {API_URL} from "./config";
import {useThemeMode} from "./hooks/useThemeMode";

export interface User {
    email: string;
    level: number;
    xp: number;
    image_url: string;
    pseudo: string;
    score: number;
    twitter_username: string;
    youtube_username: string;
    fetchInfo: () => void;
    logout: () => void
}

interface UserContextType {
    user: User;
    updateUser: (email: string, level: number, xp: number, image_url: string, pseudo: string, score: number, twitter_username: string, youtube_username: string) => void;
}

export const UserContext = createContext<UserContextType>({
    user: { email: "", level: 0, xp: 0, image_url: "", pseudo: "", score: 0, twitter_username: "", youtube_username: "", fetchInfo: () => {}, logout: () => {} },
    updateUser: (email: string, level: number, xp: number, image_url: string, pseudo: string) => {},
});

function App() {
    useThemeMode();
    const [user, setUser] = useState<User>({ email: "", level: 0, xp: 0, image_url: "", pseudo: "", score: 0, twitter_username: "", youtube_username: "", fetchInfo: fetchInfo, logout: logout });

    function updateUser(email: string, level: number, xp: number, image_url: string, pseudo: string, score: number, twitter_username: string, youtube_username: string) {
        setUser({ email: email, level: level, xp: xp, image_url: image_url, pseudo: pseudo, score: score, twitter_username: twitter_username, youtube_username: youtube_username, fetchInfo: fetchInfo, logout: logout })
    }

    function fetchInfo() {
        axios.get(`${API_URL}/api/v1/users/me`,{ withCredentials: true })
            .then(res => {
                setUser({
                    email: res.data.user.data.attributes.email,
                    level: res.data.user.data.attributes.level,
                    xp: res.data.user.data.attributes.xp,
                    image_url: res.data.user.data.attributes.image_url,
                    pseudo: res.data.user.data.attributes.pseudo,
                    score: res.data.user.data.attributes.score,
                    twitter_username: res.data.user.data.attributes.twitter_username,
                    youtube_username: res.data.user.data.attributes.youtube_username,
                    fetchInfo: fetchInfo,
                    logout: logout });
            }).catch(error => {
                console.log('Error')
            })
    }

    function logout() {
        axios.delete(`${API_URL}/api/v1/users/logout`,{ withCredentials: true })
            .then(res => {
                    setUser({
                        email: "",
                        level: 0,
                        xp: 0,
                        image_url: "",
                        pseudo: "",
                        score: 0,
                        twitter_username: "",
                        youtube_username: "",
                        fetchInfo: fetchInfo,
                        logout: logout });
                    console.log(res)
                }).catch(error => {
                    console.log('Error')
                })
    }

    useEffect(() => {
        user?.fetchInfo();
        //user?.logout();
    }, []);

    return (
        <div className="bg-[#f8f8f8] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body">
            <UserContext.Provider value={{user, updateUser}}>
                <MyRoutes />
            </UserContext.Provider>
        </div>
    );
}

export default App;
