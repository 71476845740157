import React, { FC, useState } from "react";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import {PostDataType, PrizeDataType} from "data/types";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import PostFeaturedMedia from "components/PostFeaturedMedia/PostFeaturedMedia";
import Link from "components/Link";
import Image from "../Image";
import Badge from "../Badge/Badge";

export interface CardPrizeProps {
    className?: string;
    prize: PrizeDataType;
    ratio?: string;
    hiddenAuthor?: boolean;
}

const CardPrize: FC<CardPrizeProps> = ({
   className = "h-full",
   prize,
   hiddenAuthor = false,
   ratio = "aspect-w-4 aspect-h-3",
}) => {
    const { title, sponsor, image_url, link } = prize;

    const [isHover, setIsHover] = useState(false);

    return (
        <div
            className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-slate-50 dark:bg-neutral-900 ${className}`}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            //
        >
            <div
                className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 ${ratio}`}
            >
                <div>
                    <Image src={image_url} />
                    {/*<PostFeaturedMedia post={prize} isHover={isHover} />*/}
                </div>
            </div>
            { link &&
              <Link href={link} className="absolute inset-0"></Link>
            }
            <span className="absolute top-3 inset-x-3 z-10">
                {sponsor &&
                  <div
                    className={`nc-CategoryBadgeList ${className}`}
                    data-nc-id="CategoryBadgeList"
                  >
                    <Badge
                      className=""
                      key={title}
                      name={sponsor}
                      href={link}
                    />
                  </div>
                }
            </span>
            <div className="p-4 flex flex-col space-y-3">
                <h3 className="block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <span title={title}>
            {title}
          </span>
                </h3>
            </div>
        </div>
    );
};

export default CardPrize;