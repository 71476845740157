import React, {useState} from "react";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import axios from "axios";
import {API_URL} from "../../../config";

const PageForgotPass = () => {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [errorMessageEmail, setErrorMessageEmail] = useState("")

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true)
    try {
      const response = await axios.post(`${API_URL}/api/v1/users/password`, { email: email });
      console.log(response);
      setErrorMessageEmail("");
      setEmailSent(true)
    } catch (error: any) {
      setLoading(false);
      const errorMessage = error.response?.data.email?.[0];
      if (errorMessage) {
        setErrorMessageEmail(errorMessage);
      } else {
        setErrorMessageEmail("Une erreur inattendue est survenue.");
      }
      console.error(error.response);
    }
    setLoading(false)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
        <Heading2>Tête en l'air ?</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Entre ton adresse email pour recevoir un lien de récupération
        </span>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        { !emailSent &&
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Entre ton adresse email
                </span>
                  <Input
                      type="email"
                      placeholder="exemple@exemple.com"
                      onChange={handleInputChange}
                      className="mt-1"
                      errorMessage={errorMessageEmail}
                  />
                </label>
                <ButtonPrimary
                    type="submit"
                    disabled={loading}
                >
                  {!loading ? "Valider" : "Envoi..."}
                </ButtonPrimary>
              </form>

              <span className="block text-center text-neutral-700 dark:text-neutral-300 mt-5">
              Retour à {` `}
                <NcLink href="/connexion">Connexion</NcLink>
                {` / `}
                <NcLink href="/inscription">Inscription</NcLink>
              </span>
            </div>
        }
        { emailSent &&
            <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
              Un email t'a été envoyé (vérifie les spams), ouvre-le et suis les instructions pour changer ton mot de passe ✅ 📬
            </span>
        }
      </div>
    </Layout>
  );
};

export default PageForgotPass;
