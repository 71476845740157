import React, {InputHTMLAttributes, useEffect, useState} from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    sizeClass?: string;
    fontClass?: string;
    rounded?: string;
    errorMessage?: string
}

// eslint-disable-next-line react/display-name
const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            className = "",
            sizeClass = "h-11 px-4 py-3",
            fontClass = "text-sm font-normal",
            rounded = "rounded-full",
            children,
            type = "text",
            errorMessage = "",
            ...args
        },
        ref
    ) => {
        const [stateErrorMessage, setStateErrorMessage]= useState(errorMessage)

        const borderColor = stateErrorMessage === "" ? "border-neutral-200" : "border-red-400"

        useEffect(() => {
            setStateErrorMessage(errorMessage);
        }, [errorMessage]);

        return (
            <div>
                <input
                    ref={ref}
                    type={type}
                    className={`block w-full focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:border-neutral-500 dark:focus:ring-primary-500/30 dark:bg-neutral-900 ${borderColor} ${rounded} ${fontClass} ${sizeClass} ${className}`}
                    {...args}
                />
                <p className="text-xs text-red-400">{stateErrorMessage}</p>
            </div>

        );
    }
);

export default Input;
