import React, {useContext} from "react";
import {
  DEMO_POSTS,
  DEMO_POSTS_AUDIO,
  DEMO_POSTS_GALLERY,
  DEMO_POSTS_VIDEO,
} from "data/posts";
import SectionHero from "../../components/SectionHero/SectionHero";
import rightImg from "../../images/hero-right.png";
import gifts from "../../images/home-page/tonne-de-cadeau.webp"
import quests from "../../images/home-page/quests.webp"
import me from "../../images/home-page/me.webp"
import Image from "../../components/Image";
import Vector1 from "../../images/Vector1.png";
import SectionHero2 from "../../components/Sections/SectionHero2";
import SectionHero3 from "../../components/Sections/SectionHero3";
import SectionBecomeAnAuthor from "../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import becomAuthorImg from "../../images/BecomeAnAuthorImg.png";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import {UserContext} from "../../App";

//
const MAGAZINE1_POSTS = DEMO_POSTS.filter((_, i) => i >= 8 && i < 16);
const MAGAZINE2_POSTS = DEMO_POSTS.filter((_, i) => i >= 0 && i < 7);
//

const PageHome = () => {
  const userContext = useContext(UserContext);
  const href = userContext.user.email === "" ? "/inscription" : "/mon-compte"

  return (
      <div className="nc-PageHome relative">
        <div className="container relative">
          <SectionHero
              rightImg={me}
              className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-20"
              heading="Une nouvelle façon de consommer mon contenu 🤯"
              btnText="C'est parti !"
              subHeading="Les visionnaires que vous êtes méritent le meilleur. Alors préparez-vous à révolutionner votre expérience de viewer !"
              href={href}
          />
          <div className="relative py-16">
            <BackgroundSection />
            <SectionBecomeAnAuthor
                className="py-16 lg:py-28"
                rightImg={gifts}
                title="Une tonne de cadeaux à gagner 🎁"
                text="Remplis des quêtes chaque jour et gagne des lots incroyables ! Des NFT, de la crypto, de vrais objets... tout peut y passer !"
                reverse={true}
                btnText="C'est parti"
                href={href}
            />
          </div>
          <SectionBecomeAnAuthor
              className="py-16 lg:py-28"
              rightImg={quests}
              title="Ta fidélité récompensée ❤️"
              text="Grâce aux quêtes que tu complètes, ta fidèlité sera mesurée et récompensée au mieux ! Que ce soit aujourd'hui ou dans le futur..."
              reverse={false}
              btnText="C'est parti"
              href={href}
          />
        </div>
      </div>
  );
};

export default PageHome;


