import React, {useContext, useEffect, useRef, useState} from "react";
import {
  CollectionDataType,
  LeaderboardUser,
  PackDataType,
  PrizeDataType,
  PropositionDataType,
  QuestDataType, StickerType
} from "data/types";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import CardPrize from "components/CardPrize/CardPrize";
import Image from "components/Image";
import CardCategory3 from "../../components/CardCategory3/CardCategory3";
import Card5 from "../../components/Card5/Card5";
import axios from "axios";
import CardQuest from "../../components/CardQuest/CardQuest";
import {QuestState} from "../../data/enums";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import {UserContext} from "../../App";
import chest from '../../images/coffre.webp';
import stickerPacks from '../../images/sticker-packs.webp'
import stickerPack from '../../images/sticker-pack.webp'
import NcModal from "../../components/NcModal/NcModal";
import {animated, useSpring} from 'react-spring';
import {STICKERS_IMAGE_COLLECTION_2} from "../../data/stickers"
import {OPENING_ANIMATIONS} from '../../data/opening-animation'
import {useLocation, useNavigate} from "react-router-dom";
import {API_URL} from "../../config";

import euros300 from "../../images/prizes/300euros.webp"
import SummerAxie from "../../images/prizes/SummerAxie.webp"
import SLP from "../../images/prizes/SLP.webp"
import BERRY from "../../images/prizes/BERRY.webp"
import Shrapnel from "../../images/prizes/Shrapnel.webp"
import AgoraLootbox from "../../images/prizes/AgoraLootbox.webp"
import AXS from "../../images/prizes/AXS.webp"
import RON from "../../images/prizes/RON.webp"
import SHRAP from "../../images/prizes/SHRAP.webp"
import SAND from "../../images/prizes/Sand.webp"
import PIXEL from "../../images/prizes/PIXEL.webp"
import SkinWF from "../../images/prizes/Skin Wild Forest.webp"
import Key from "../../images/prizes/Key.webp"
import Kaidro from "../../images/prizes/Kaidro.webp"
import SNIPER from "../../images/prizes/Sniper.webp"
import CTA from "../../images/prizes/CTA.webp"
import Apostle from "../../images/prizes/Apostle.webp"
import ArchiveFilterListBox from "../../components/ArchiveFilterListBox/ArchiveFilterListBox";
import Leaderboard from "../../components/Leaderboard/leaderboard";
import Nyangvine from "../../images/prizes/Nyangvine.webp"

const TABS = ["Quêtes", "Collections", "classement"];
const prizes: PrizeDataType[] = [
  { title: "1 Nyangvine",
    sponsor: "Tirage au sort : 02/06",
    image_url: Nyangvine,
    link: ""
  },
  { title: "Gagnant : Einsteinium",
    sponsor: "Tirage au sort : 31/05",
    image_url: Apostle,
    link: ""
  },
  { title: "10 $SAND",
    sponsor: "Gagnant : WAEL",
    image_url: SAND,
    link: ""
  },
  { title: "2 RON",
    sponsor: "Gagnant : Bnji",
    image_url: RON,
    link: ""
  },
  { title: "550€ de NFT Wild Forest à se partager",
    sponsor: "7 gagnants par jour jusqu'au 01/05",
    image_url: SNIPER,
    link: ""
  },
  { title: "10 $PIXEL",
    sponsor: "Gagnant : think tattoo",
    image_url: PIXEL,
    link: ""
  },
]

const FILTERS = [
  { name: "Depuis toujours" },
  { name: "Du mois" },
  { name: "De la semaine" },
];

const PageUser = () => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [tabActive, setTabActive] = useState<string>(TABS[location.state?.tabActive] || TABS[0]);
  const [collections, setCollections] = useState<CollectionDataType[]>([])
  const [packs, setPacks] = useState<PackDataType[]>([])
  const [propositions, setPropositions] = useState<PropositionDataType[]>([])
  const [quests, setQuests] = useState<QuestDataType[]>([])
  const [completedQuests, setCompletedQuests] = useState<QuestDataType[]>([])

  const [isEditing, setIsEditing] = useState(false)
  const [tempName, setTempName] = useState(userContext.user.pseudo)

  const [showVideo, setShowVideo] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [stickerIds, setStickerIds] = useState<number[] | []>([]);
  const [buttonText, setButtonText] = useState("Ouvrir")
  const [buttonTextAll, setButtonTextAll] = useState("Tout ouvrir")
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [selectedAnimation, setSelectedAnimation] = useState('common');

  const [allTimeLeaderboard, setAllTimeLeaderboard] = useState<LeaderboardUser[]>([])
  const [monthlyLeaderboard, setMonthlyLeaderboard] = useState<LeaderboardUser[]>([])
  const [weeklyLeaderboard, setWeeklyLeaderboard] = useState<LeaderboardUser[]>([])
  const [activeLeaderboard, setActiveLeaderboardFilter] = useState<LeaderboardUser[]>([])

  const rarityLevels: Record<string, number> = {
    'commun': 1,
    'rare': 2,
    'épique': 3,
    'légendaire': 4,
    'spécial': 5
  };

  const handleOpenPack = async (all: boolean) => {
    if (all && buttonText === "Ouvrir") {
      try {
        setButtonTextAll("Ouverture...")
        setIsButtonDisabled(true);
        const response = await axios.post(`${API_URL}/api/v1/users/redeem_all_user_stickers`, {}, {withCredentials: true})

        const stickers: StickerType[] = response.data.stickers;
        let highestRarity = 'commun';
        let highestRarityLevel = 1;

        stickers.forEach(sticker => {
          const currentRarityLevel = rarityLevels[sticker.rarity];
          if (currentRarityLevel > highestRarityLevel) {
            highestRarity = sticker.rarity;
            highestRarityLevel = currentRarityLevel;
          }
        });

        setStickerIds(stickers.map(sticker => sticker.id))
        const animationType = isSafari() ? highestRarity + 'Mov' : highestRarity;
        setSelectedAnimation(OPENING_ANIMATIONS[animationType]);
        setPacks([]);
        setShowVideo(true);
        setShowImage(false);
        setButtonText("Collecter");
        setButtonTextAll("Collecter");
        setIsButtonDisabled(true);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    } else if (!all && buttonText === "Ouvrir") {
      try {
        const response = await axios.post(`${API_URL}/api/v1/users/redeem_user_sticker`, {
          pack_id: packs[0].id
        }, { withCredentials: true });
        const sticker: StickerType = response.data.sticker
        setStickerIds([sticker.id])
        const animationType = isSafari() ? sticker.rarity + 'Mov' : sticker.rarity;
        setSelectedAnimation(OPENING_ANIMATIONS[animationType]);
        setPacks(prevPacks => prevPacks.slice(1));
        setShowVideo(true);
        setShowImage(false);
        setButtonText("Collecter");
        setButtonTextAll("Collecter");
        setIsButtonDisabled(true);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    } else if (buttonText === "Collecter") {
      setShowVideo(false);
      setShowImage(false);
      setButtonText("Ouvrir");
      setButtonTextAll("Tout ouvrir");
      if (packs.length === 0) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
  };

  const handleVideoTimeUpdate = () => {
    if (videoRef.current && videoRef.current.currentTime >= (0.8 * videoRef.current.duration) && !showImage) {
      setShowImage(true);
    }
  };

  const handleVideoEnded = () => {
    setShowVideo(false);
    setIsButtonDisabled(false); // Réactivez le bouton une fois la vidéo terminée
  };

  const renderModalContent = () => (
      <div className="p-8">
        <h2 className="text-2xl font-semibold mb-4">Les lots en jeu :</h2>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
          {prizes.map((prize) => (
              <CardPrize key={prize.title} prize={prize} />
          ))}
        </div>
      </div>
  );

  const animationProps = useSpring({
    from: { transform: 'scale(0)' },
    to: { transform: 'scale(1)' },
    config: { tension: 170, friction: 12 },
    reset: !showImage, // Réinitialiser l'animation lorsque showImage est false
    reverse: !showImage // Jouer l'animation à l'envers lorsque showImage est false
  });

  const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const renderPacksModalContent = () => {
    return (
        <div className="p-8 flex flex-col items-center justify-center relative">
          {showVideo &&
              <video
                  ref={videoRef}
                  src={selectedAnimation}
                  onTimeUpdate={handleVideoTimeUpdate}
                  onEnded={handleVideoEnded}
                  autoPlay
                  muted
                  playsInline
                  className="h-80 mb-20 z-20 absolute"
              />
          }

          <animated.div style={animationProps}
                        className={`${showImage ? 'absolute visible' : 'absolute invisible'} overflow-x-auto mb-5 ${stickerIds.length > 1 ? 'w-full' : ''}`}>
            <div className="flex gap-x-4">
              {stickerIds.map((id) => (
                  <Image key={id} src={STICKERS_IMAGE_COLLECTION_2[id] || chest}
                         className="h-72 z-10 flex-auto"/>
              ))}
            </div>
          </animated.div>

          <Image src={stickerPack}
                 className={`h-[350px] mb-5 ${!showVideo && !showImage && packs.length > 0 ? 'visible' : 'invisible'}`}/>

          {packs.length === 0 && !showVideo && !showImage &&
              <p className="text-center text-xl font-bold absolute">Remplis des quêtes pour plus de packs</p>
          }

          <div className="flex gap-x-3">
            <ButtonPrimary
                onClick={() => handleOpenPack(false)}
                disabled={isButtonDisabled}
                className={isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}
            >
              {buttonText}
            </ButtonPrimary>
            {buttonText !== "Collecter" && (
              <ButtonPrimary
                  onClick={() => handleOpenPack(true)}
                  disabled={isButtonDisabled}
                  className={isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}
              >
                {buttonTextAll}
              </ButtonPrimary>
            )}
          </div>
        </div>
    );
  }

  const handleNameChange = async () => {
    try {
      await axios.put(`${API_URL}/api/v1/users/update_pseudo`, { pseudo: tempName }, { withCredentials: true });
      userContext.user.pseudo = tempName;
      setIsEditing(false);
    } catch (error) {
      console.error(error);
    }
  };

  const markQuestAsCompleted = (quest: QuestDataType, state: QuestState) => {
    quest.state = state;
    if (state === QuestState.COMPLETED) {
      setCompletedQuests(prevState => [...prevState, quest]);
      setQuests(prevQuests => prevQuests.filter(q => q.id !== quest.id));
      userContext.user.fetchInfo();
    }
  };

  const handleClickTab = (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
  };

  const sortIncompleteQuests = (incompleteQuests: QuestDataType[]) => {
    const toVerifyQuests = incompleteQuests.filter(quest => quest.state === QuestState.TO_VERIFY);
    const otherQuests = incompleteQuests.filter(quest => quest.state !== QuestState.TO_VERIFY);
    const sortedQuests = otherQuests.concat(toVerifyQuests);

    return sortedQuests
  }

  const handleSelectionChange = (selectedItem: { name: string }) => {
    const selectedIndex = FILTERS.findIndex(filter => filter.name === selectedItem.name);
    switch (selectedIndex) {
      case 0:
        setActiveLeaderboardFilter(allTimeLeaderboard)
        break;
      case 1:
        setActiveLeaderboardFilter(monthlyLeaderboard)
        break;
      case 2:
        setActiveLeaderboardFilter(weeklyLeaderboard)
        break;
    }
  };

  useEffect(() => {
    const fetchCollections = async () => {
      if (tabActive === TABS[0]) {
        try {
          const response = await axios.get<QuestDataType[]>(`${API_URL}/api/v1/quests`, {withCredentials: true})
          const incompleteQuests = response.data.filter(quest => quest.state === QuestState.UNCOMPLETED || quest.state === QuestState.X_NOT_LINKED || quest.state === QuestState.TO_VERIFY);
          const completedQuestIds = response.data.filter(quest => quest.state === QuestState.COMPLETED);

          const sortedIncompleteQuests = sortIncompleteQuests(incompleteQuests)

          setQuests(sortedIncompleteQuests);
          setCompletedQuests(completedQuestIds);
          console.log(response.data)
        } catch (error) {
          console.error(error)
        }
        try {
          const response = await axios.get<PackDataType[]>(`${API_URL}/api/v1/users/packs`, {withCredentials: true})

          setPacks(response.data);
          console.log(response.data)
        } catch (error) {
          console.error(error)
        }
        navigate(location.pathname, {
          state: {
            ...location.state,
            tabActive: undefined
          },
          replace: true
        });
      }

      if (tabActive === TABS[1]) {
        try {
          const response = await axios.get<CollectionDataType[]>(`${API_URL}/api/v1/users/collections`, {withCredentials: true})
          console.log(response)
          setCollections(response.data.sort((a, b) => Number(b.id) - Number(a.id)))
        } catch (error) {
          console.error(error)
        }
      }

      if (tabActive === TABS[2]) {
        try {
          const response = await axios.get(`${API_URL}/api/v1/leaderboard`, {withCredentials: true})
          setAllTimeLeaderboard(response.data.all_time)
          setMonthlyLeaderboard(response.data.monthly)
          setWeeklyLeaderboard(response.data.weekly)
          setActiveLeaderboardFilter(response.data.all_time)
          console.log(response.data)
        } catch (error) {
          console.error(error)
        }
      }
    };

    fetchCollections();
  }, [tabActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<PackDataType[]>(`${API_URL}/api/v1/users/packs`, {withCredentials: true})
        setPacks(response.data);
        setIsButtonDisabled(false);
        console.log(response.data)
      } catch (error) {
        console.error(error)
      }
    };

    fetchData();
  }, [userContext.user.xp]);

  const renderProgressBar = () => {
    return (
        <div>
          {isEditing ? (
              <>
                <input
                    type="text"
                    value={tempName}
                    onChange={(e) => setTempName(e.target.value)}
                    className="border rounded"
                />
                <button onClick={handleNameChange} className="btn ml-3">✅</button>
              </>
          ) : (
              <>
                <h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
                  <span>{userContext.user.pseudo}</span>
                  <button onClick={() => setIsEditing(true)} className="ml-2 text-l sm:text-xl lg:text-2xl mirror-text">✏️</button>
                </h2>
              </>
          )}
          <span className="block text-sm mt-2 text-neutral-500 dark:text-neutral-400 pt-5">
            <ProgressBar
                currentXP={userContext.user.xp}
                maxXP={100}
                level={userContext.user.level}
                score={userContext.user.score} />
          </span>
        </div>
    )
  }


  return (
    <div className={`nc-PageAuthor `}>
      {/* HEADER */}
      <div className="w-full">
        <div className="container mt-8 md:mt-16 lg:mt-24">
          <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row min-h-[taille]">

            {/* Desktop Layout */}
            <div className="hidden md:flex md:flex-row md:w-full">
              {/* Coffre */}
              <div className="flex flex-col items-center w-32 lg:w-40 flex-shrink-0 md:mt-0">
                <div className="relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold w-20 h-20 text-xl lg:text-2xl lg:w-36 lg:h-36 ring-4 ring-white dark:ring-0 z-0">
                  <Image
                      alt="Coffre"
                      src={chest}
                      fill
                      className="object-cover"
                  />
                </div>
                <NcModal
                    renderTrigger={(openModal) => (
                        <button
                            onClick={openModal}
                            className="text-gray-500 hover:text-yellow-700 cursor-pointer mt-2"
                        >
                          <span className={"text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900"}>Voir contenu du coffre</span>
                        </button>
                    )}
                    modalTitle="Contenu du Coffre"
                    renderContent={renderModalContent}
                />
              </div>

              {/* Barre de progression */}
              <div className="pt-5 md:pt-1 lg:ml-6 xl:ml-12 flex-grow">
                <div className="max-w-screen-sm space-y-3.5 md:mx-5">
                  {renderProgressBar()}
                </div>
              </div>


              {/* Stickers */}
              <div className="flex flex-col items-center w-32 lg:w-52 flex-shrink-0 md:mt-0">
                <div className="relative"> {/* Container avec position relative pour la superposition */}
                  <Image className="w-32 lg:w-52 mb-5" src={stickerPacks}></Image>

                  {packs.length > 0 && (
                      <div className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center">
                        {packs.length} {/* Affiche le nombre de packs */}
                      </div>
                  )}
                </div>

                <NcModal
                    renderTrigger={(openModal) => (
                        <ButtonPrimary
                            onClick={openModal}
                            sizeClass="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
                            className={packs.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}
                            disabled={packs.length === 0}>
                          Ouvre tes packs
                        </ButtonPrimary>
                    )}
                    modalTitle="Ouvre tes packs"
                    renderContent={renderPacksModalContent}
                />
              </div>
            </div>

            <div className="md:hidden flex flex-col w-full">
              {/* Barre de progression */}
              <div className="pt-5">
                <span className="block text-sm mb-5">
                    {renderProgressBar()}
                </span>
              </div>

              {/* Coffre et Stickers */}
              <div className="flex flex-row justify-between mt-5">
                {/* Code du coffre */}
                <div className="flex flex-col items-center w-32 flex-shrink-0">
                  <div className="relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold w-20 h-20 text-xl lg:text-2xl lg:w-36 lg:h-36 ring-4 ring-white dark:ring-0 z-0">
                    <Image
                        alt="Coffre"
                        src={chest}
                        fill
                        className="object-cover"
                    />
                  </div>
                  <NcModal
                      renderTrigger={(openModal) => (
                          <button
                              onClick={openModal}
                              className="text-gray-500 hover:text-yellow-700 cursor-pointer mt-2"
                          >
                            <span className={"text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900"}>Voir contenu du coffre</span>
                          </button>
                      )}
                      modalTitle="Contenu du Coffre"
                      renderContent={renderModalContent}
                  />
                </div>

                {/* Code des stickers */}
                <div className="flex flex-col items-center w-32 flex-shrink-0">
                  <div className="relative">
                    <Image className="w-32 mb-5" src={stickerPacks}></Image>

                    {packs.length > 0 && (
                        <div className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center">
                          {packs.length} {/* Affiche le nombre de packs */}
                        </div>
                    )}
                  </div>

                  <NcModal
                      renderTrigger={(openModal) => (
                          <ButtonPrimary
                              onClick={openModal}
                              sizeClass="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
                              className={packs.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}
                              disabled={packs.length === 0}>
                            Ouvre tes packs
                          </ButtonPrimary>
                      )}
                      modalTitle="Ouvre tes packs"
                      renderContent={renderPacksModalContent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-12 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          {/* TABS FILTER */}
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <Nav className="sm:space-x-2">
              {TABS.map((item, index) => (
                <NavItem
                  key={index}
                  isActive={tabActive === item}
                  onClick={() => handleClickTab(item)}
                >
                  {item}
                </NavItem>
              ))}
            </Nav>
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
            { tabActive === TABS[2] &&
              <div className="flex justify-end">
                <ArchiveFilterListBox lists={FILTERS} onSelectionChange={handleSelectionChange} />
              </div>
            }
          </div>

          {/* Ad Space for Mobile */}
          <div className="block md:hidden mb-6">
            <div className="h-full bg-gray-200 hidden">Espace Publicitaire</div>
          </div>

          {/* LOOP ITEMS */}
          {tabActive === TABS[0] &&
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 mt-8 lg:mt-10">
                {/* Cette div enveloppe les CardQuest et prend 2/3 de la largeur */}
                <div className="col-span-1 md:col-span-2">
                  <div className="grid grid-cols-1 gap-6 md:gap-8">
                    {quests.map((quest, index) => (
                        <CardQuest
                            key={quest.id}
                            quest={quest}
                            onComplete={(state: QuestState) => markQuestAsCompleted(quest, state)}
                        />
                    ))}
                  </div>

                  <div className="my-6 border-t border-gray-300"></div>

                  <div className="grid grid-cols-1 gap-6 md:gap-8">
                    <h2 className="text-xl font-semibold">Quêtes complétées</h2>
                    {completedQuests.map((quest, index) => (
                        <CardQuest
                            key={quest.id}
                            quest={quest}
                            onComplete={(state: QuestState) => markQuestAsCompleted(quest, state)}
                        />
                    ))}
                  </div>
                </div>

                {/* Cette div est pour l'espace publicitaire et prend 1/3 de la largeur */}
                <div className="hidden md:block col-span-1">
                  {/* Votre code publicitaire ici */}
                  <div className="h-full bg-gray-200 hidden">Espace Publicitaire</div>
                </div>
              </div>
          }

          {tabActive === TABS[1] &&
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
                {collections.map((collection) => (
                    <CardCategory3 key={collection.id} collection={collection}/>
                ))}
              </div>
          }

          {tabActive === TABS[2] &&
              <div className="mt-8 gap-6 md:gap-8">
                <Leaderboard leaderboard={activeLeaderboard}/>
              </div>
          }
        </main>
      </div>
    </div>
  );
};

export default PageUser;