import React, {useContext} from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import getStripe from "../../../lib/getStripe";
import {UserContext} from "../../../App";
import {API_URL} from "../../../config";

export interface PricingItem {
  isPopular: boolean;
  name: string;
  pricing: string;
  desc: string;
  per: string;
  features: string[];
  stripeId: string
}

const pricings: PricingItem[] = [
  {
    isPopular: false,
    name: "Un peu",
    pricing: "5€",
    per: "",
    features: ["Automated Reporting", "Faster Processing", "Customizations"],
    desc: ` Literally you probably haven't heard of them jean shorts.`,
    stripeId: 'price_1NJHhBB1IcIwVKXUzGXnI3OS'
  },
  {
    isPopular: false,
    name: "beaucoup",
    pricing: "10€",
    per: "",
    features: [
      "Everything in Starter",
      "100 Builds",
      "Progress Reports",
      "Premium Support",
    ],
    desc: ` Literally you probably haven't heard of them jean shorts.`,
    stripeId: 'price_1NJHhhB1IcIwVKXUqFrivaov'
  },
  {
    isPopular: false,
    name: "passionnément",
    pricing: "20€",
    per: "",
    features: [
      "Everything in Basic",
      "Unlimited Builds",
      "Advanced Analytics",
      "Company Evaluations",
    ],
    desc: ` Literally you probably haven't heard of them jean shorts.`,
    stripeId: 'price_1NJHhyB1IcIwVKXUUK1USC6Z'
  },{
    isPopular: false,
    name: "À la folie",
    pricing: "50€",
    per: "",
    features: [
      "Everything in Basic",
      "Unlimited Builds",
      "Advanced Analytics",
      "Company Evaluations",
    ],
    desc: ` Literally you probably haven't heard of them jean shorts.`,
    stripeId: 'price_1NJHiEB1IcIwVKXUoL6CGIRQ'
  },
];

const PageSubcription = () => {
  const userContext = useContext(UserContext);

  async function handleCheckout(index: number) {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: pricings[index].stripeId,
          quantity: 1,
        },
      ],
      mode: 'payment',
      successUrl: `${API_URL}/achat/succes`,
      cancelUrl: `http://localhost:3001/achat`,
      customerEmail: userContext.user.email,
      clientReferenceId: userContext.user.email
    });
    console.warn(error.message);
  }

  const renderPricingItem = (pricing: PricingItem, index: number) => {
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
          pricing.isPopular
            ? "border-primary-500"
            : "border-neutral-100 dark:border-neutral-700"
        }`}
      >
        {pricing.isPopular && (
          <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {pricing.name}
          </h3>
          <h2 className="text-5xl leading-none flex items-center text-neutral-700 dark:text-neutral-300">
            <span>{pricing.pricing}</span>
            <span className="text-lg ml-1 font-normal text-neutral-500 dark:text-neutral-300">
              {pricing.per}
            </span>
          </h2>
        </div>
        <nav className="space-y-4 mb-8">
          {pricing.features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                <CheckIcon className="w-5 h-5" aria-hidden="true" />
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">
                {item}
              </span>
            </li>
          ))}
        </nav>
        <div className="flex flex-col mt-auto">
          {pricing.isPopular ? (
            <div className="flex flex-col mt-auto gap-1">
              <ButtonPrimary>Acheter en €</ButtonPrimary>
              <ButtonPrimary>Acheter en crypto</ButtonPrimary>
            </div>
          ) : (
            <div className="flex flex-col gap-1 mt-auto">
              <ButtonSecondary onClick={() => handleCheckout(index)}>
                <span className="font-medium">Acheter en €</span>
              </ButtonSecondary>
              <ButtonSecondary>
                <span className="font-medium">Acheter en crypto</span>
              </ButtonSecondary>
            </div>
          )}
          <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">
            {pricing.desc}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
        <Heading2 emoji="">Achète des packs</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Pricing to fit the needs of any companie size.
        </span>
      </header>

      <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
        <div className="grid lg:grid-cols-4 gap-5 xl:gap-8">
          {pricings.map(renderPricingItem)}
        </div>
      </section>
    </Layout>
  );
};

export default PageSubcription;
