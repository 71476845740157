import React, { FC } from 'react';

type ProgressBarProps = {
    currentXP: number;
    maxXP: number;
    level: number;
    score: number;
};

const ProgressBar: FC<ProgressBarProps> = ({ currentXP, maxXP, level, score }) => {
    const percentageFilled = (currentXP / maxXP) * 100;

    return (
        <div className="relative">
            <span className="absolute top-[-1.5em] left-0 text-neutral-500 dark:text-neutral-400">{`Niveau ${level}`}</span>
            <span className="absolute top-[-1.5em] right-0 text-neutral-500 dark:text-neutral-400">{`${currentXP}/100 XP`}</span>
            <div className="w-full h-4 bg-gray-300 rounded-full">
                <div
                    className="h-full bg-primary-700 rounded-full"
                    style={{ width: `${percentageFilled}%` }}
                ></div>
            </div>
            <span className="absolute bottom-[-1.5em] left-0 text-yellow-500 text-xl font-medium">{`Score : ${score}`}</span>
        </div>
    );
};

export default ProgressBar;
