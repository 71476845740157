import SectionSuccessBuy from "../../../../components/SectionSuccessBuy/SectionSuccessBuy";
import BackgroundSection from "../../../../components/BackgroundSection/BackgroundSection";
import React from "react";

const SuccessBuy = () => {
    return (
        <div className="container relative">
            <div className="relative py-16 my-12">
                <BackgroundSection />
                <SectionSuccessBuy reverse title="Merci pour ton achat !" text="Fonce ouvrir tes packs !"/>
            </div>
        </div>
    )
}

export default SuccessBuy