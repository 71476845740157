import React from 'react';
import {LeaderboardUser} from "../../data/types";

type LeaderboardProps = {
    leaderboard: LeaderboardUser[];
};

const Leaderboard: React.FC<LeaderboardProps> = ({ leaderboard }) => {
    return (
        <div className="p-4 bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 rounded-lg">
            <ul className="divide-y divide-gray-200">
                {leaderboard.map((user, index) => (
                    <li key={user.id} className="py-2 flex justify-between items-center">
                        <div>
                            <span>{index + 1}. {user.pseudo} </span>
                            <span className="italic">(niv. {user.level})</span>
                        </div>
                        <span className="font-bold">{user.score} points</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Leaderboard;
