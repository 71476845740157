import React, { FC } from "react";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Image from "../Image";
import ButtonSecondary from "../Button/ButtonSecondary";

export interface SectionSuccessBuyProps {
    className?: string;
    rightImg?: string;
    title?: string;
    text?: string;
    reverse?: boolean
}

const SectionSuccessBuy: FC<SectionSuccessBuyProps> = ({
   className = "",
   rightImg = rightImgDemo,
   title = "",
   text = "",
   reverse= false
}) => {
    if (reverse) {
        return (
            <div
                className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
            >
                <div className="flex-grow">
                    <Image
                        alt="hero"
                        sizes="(max-width: 768px) 100vw, 50vw"
                        src={rightImg}
                    />
                </div>
                <div className="flex-shrink-0 mb-14 lg:mb-0 lg:ml-10 lg:w-2/5">
                    <h2 className="font-semibold text-3xl sm:text-4xl mt-3">
                        {title}
                    </h2>
                    <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
              {text}
            </span>
                    <ButtonPrimary href={"/ouvrir-packs"} className="mt-8 mr-6">Ouvre tes packs</ButtonPrimary>
                    <ButtonSecondary href={"/achat"}>En acheter +</ButtonSecondary>
                </div>
            </div>
        );
    }

    return (
        <div
            className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
        >
            <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
                <h2 className="font-semibold text-3xl sm:text-4xl mt-3">
                    {title}
                </h2>
                <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
          {text}
        </span>
                <ButtonPrimary className="mt-8">Ouvre tes packs</ButtonPrimary>
            </div>
            <div className="flex-grow">
                <Image
                    alt="hero"
                    sizes="(max-width: 768px) 100vw, 50vw"
                    src={rightImg}
                />
            </div>
        </div>
    );
};

export default SectionSuccessBuy;