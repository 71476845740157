import ButtonPrimary from "../Button/ButtonPrimary";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {UserContext} from "../../App";
import {API_URL} from "../../config";

const PageSettings = () => {
    const [isLoading, setIsLoading] = useState(false);

    const userContext = useContext(UserContext);

    const handleConnectXClick = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}/api/v1/users/auth_twitter`, {withCredentials: true})
            setIsLoading(false);
            window.location.href = response.data.authorize_url;
        } catch (error) {
            console.error("Erreur lors de la connexion à X:", error);
            alert("Une erreur est survenue. Veuillez réessayer.");
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-[50vh]">
            <div className="grid grid-cols-2 gap-0 w-full items-center mb-4 space-x-5">
                <div className="flex justify-end">
                    <ButtonPrimary
                        onClick={handleConnectXClick}
                        disabled={isLoading}
                    >
                        Connecter X
                    </ButtonPrimary>
                </div>
                <div className="flex">
                    <span className={userContext.user.twitter_username ? 'text-green-500' : 'text-red-500'}>
                        {userContext.user.twitter_username || 'Non connecté'}
                    </span>
                </div>
            </div>
            {/*<div className="grid grid-cols-2 gap-0 w-full items-center space-x-5">*/}
            {/*    <div className="flex justify-end">*/}
            {/*        <ButtonPrimary>*/}
            {/*            Connecter Youtube*/}
            {/*        </ButtonPrimary>*/}
            {/*    </div>*/}
            {/*    <div className="flex">*/}
            {/*        <span className={userContext.user.youtube_username ? 'text-green-500' : 'text-red-500'}>*/}
            {/*            {userContext.user.youtube_username || 'Non connecté'}*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default PageSettings