import React, { FC } from "react";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Image from "../Image";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
  title?: string;
  text?: string;
  reverse?: boolean
  btnText?: string
  href?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
  title = "",
  text = "",
  reverse= false,
  btnText= "",
  href= "/"
}) => {
  if (reverse) {
    return (
        <div
            className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
        >
          <div className="flex-grow">
            <Image
                alt="hero"
                sizes="(max-width: 768px) 100vw, 50vw"
                src={rightImg}
            />
          </div>
          <div className="flex-shrink-0 mb-14 lg:mb-0 lg:ml-10 lg:w-2/5">
            <h2 className="font-semibold text-3xl sm:text-4xl mt-3">
              {title}
            </h2>
            <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
              {text}
            </span>
            <ButtonPrimary href={href} className="mt-8">{btnText}</ButtonPrimary>
          </div>
        </div>
    );
  }

  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
    >
      <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-3xl sm:text-4xl mt-3">
          {title}
        </h2>
        <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
          {text}
        </span>
        <ButtonPrimary href={href} className="mt-8">{btnText}</ButtonPrimary>
      </div>
      <div className="flex-grow">
        <Image
          alt="hero"
          sizes="(max-width: 768px) 100vw, 50vw"
          src={rightImg}
        />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
