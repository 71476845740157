import common from "images/pack-opening-animations/common.webm"
import rare from "images/pack-opening-animations/rare.webm"
import epic from "images/pack-opening-animations/epic.webm"
import legendary from "images/pack-opening-animations/legendary.webm"
import special from "images/pack-opening-animations/prize.webm"

import commonMov from "images/pack-opening-animations/common.mov"
import rareMov from "images/pack-opening-animations/rare.mov"
import epicMov from "images/pack-opening-animations/epic.mov"
import legendaryMov from "images/pack-opening-animations/legendary.mov"
import specialMov from "images/pack-opening-animations/prize.mov"

const OPENING_ANIMATIONS: Record<string, string> = {
    commun: common,
    rare: rare,
    épique: epic,
    légendaire: legendary,
    spécial: special,
    communMov: commonMov,
    rareMov: rareMov,
    épiqueMov: epicMov,
    légendaireMov: legendaryMov,
    spécialMov: specialMov
};

export { OPENING_ANIMATIONS }