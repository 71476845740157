import React, {useContext, useState} from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import Heading2 from "components/Heading/Heading2";
import Image from "components/Image";
import Layout from "../layout";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../../../config";
import {UserContext} from "../../../App";

const PageSignUp = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errorMessageEmail, setErrorMessageEmail] = useState("")
  const [errorMessagePassword, setErrorMessagePassword] = useState("")
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await axios.post(
          `${API_URL}/api/v1/users/signup`,
          { api_v1_user: {
              email: formData.email,
              password: formData.password,
              password_confirmation: formData.password
            }},
          { withCredentials: true });
      userContext.updateUser(
          formData.email,
          response.data.data.level,
          response.data.data.xp,
          response.data.data.image_url,
          response.data.data.pseudo,
          response.data.data.score,
          response.data.data.twitter_username,
          response.data.data.youtube_username)
      navigate("/mon-compte")
      console.log(response);
    } catch (error: any) {
      setErrorMessageEmail(" ")
      setErrorMessagePassword(error.response.data.status.message)
      console.error(error.response);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-10">
        <Heading2>Inscription</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Bienvenue sur notre outil
        </span>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Adresse email
            </span>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="example@example.com"
              className="mt-1"
              errorMessage={errorMessageEmail}
            />
          </label>
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Mot de passe
            </span>
            <Input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="mt-1"
                errorMessage={errorMessagePassword}
            />
          </label>
          <ButtonPrimary type="submit">S'inscrire</ButtonPrimary>
        </form>

        {/* ==== */}
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Vous avez déjà un compte ? {` `}
          <NcLink href="/connexion">Se connecter</NcLink>
        </span>
      </div>
    </Layout>
  );
};

export default PageSignUp;
