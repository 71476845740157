import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe('pk_test_51NJGlfB1IcIwVKXUhfafBEuv9JrZ71EoUOhDmlUFGTH4G4DTFZIaCmk41AtOIDSOUbApSVLWEg7U1wfaXNixzVCP00XLPQarML');
  }
  return stripePromise;
};

export default getStripe;