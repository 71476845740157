import React, {useState} from "react";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import axios from "axios";
import {API_URL} from "../../../config";
import {useSearchParams} from "react-router-dom";

const PageResetPass = () => {
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [passwordReset, setPasswordReset] = useState(false)
    const [errorMessagePassword, setErrorMessagePassword] = useState("")

    const [searchParams] = useSearchParams();
    const resetPasswordToken = searchParams.get('reset_password_token');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            setErrorMessagePassword("Les mots de passe ne correspondent pas.");
            return;
        }

        setLoading(true)
        try {
            const response = await axios.patch(`${API_URL}/api/v1/users/password`, {
                reset_password_token: resetPasswordToken, // Assurez-vous d'avoir extrait le token de l'URL
                password: password,
                password_confirmation: confirmPassword,
            });
            console.log(response);
            setErrorMessagePassword("");
            setPasswordReset(true)
        } catch (error: any) {
            setLoading(false);
            const errorMessage = error.response?.data.password?.[0];
            if (errorMessage) {
                setErrorMessagePassword(errorMessage);
            } else {
                setErrorMessagePassword("Une erreur inattendue est survenue.");
            }
            console.error(error.response);
        }
        setLoading(false)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === 'password') {
            setPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }
    };

    return (
        <Layout>
            <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
                <Heading2>Tête en l'air ?</Heading2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                    Entre ton nouveau mot de passe
                </span>
            </header>

            <div className="max-w-md mx-auto space-y-6">
                {/* FORM */}
                { !passwordReset &&
                    <div>
                      <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                        <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Nouveau mot de passe
                        </span>
                          <Input
                            type="password"
                            name="password"
                            placeholder="Nouveau mot de passe"
                            onChange={handleInputChange}
                            className="mt-1"
                          />
                        </label>
                        <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          Confirmation du mot de passe
                        </span>
                          <Input
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirmation du mot de passe"
                            onChange={handleInputChange}
                            className="mt-1"
                            errorMessage={errorMessagePassword}
                          />
                        </label>
                        <ButtonPrimary
                          type="submit"
                          disabled={loading}
                        >
                            {!loading ? "Valider" : "Envoi..."}
                        </ButtonPrimary>
                      </form>

                    {/* ==== */}

                        <span className="block text-center text-neutral-700 dark:text-neutral-300 mt-5">
                            Retour à {` `}
                            <NcLink href="/connexion">Connexion</NcLink>
                              {` / `}
                            <NcLink href="/inscription">Inscription</NcLink>
                        </span>
                    </div>
                }
                { passwordReset &&
                    <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                        Ton mot de passe a bien été changé, {` `}
                        <NcLink href="/connexion">connecte-toi</NcLink>
                    </span>
                }
            </div>
        </Layout>
    );
};

export default PageResetPass;