import common1 from "images/collection-1/common/sticker-common-1.webp"
import common2 from "images/collection-1/common/sticker-common-2.webp"
import common3 from "images/collection-1/common/sticker-common-3.webp"
import common4 from "images/collection-1/common/sticker-common-4.webp"
import common5 from "images/collection-1/common/sticker-common-5.webp"
import common6 from "images/collection-1/common/sticker-common-6.webp"
import rare1 from "images/collection-1/rare/sticker-rare-1.webp"
import rare2 from "images/collection-1/rare/sticker-rare-2.webp"
import rare3 from "images/collection-1/rare/sticker-rare-3.webp"
import rare4 from "images/collection-1/rare/sticker-rare-4.webp"
import rare5 from "images/collection-1/rare/sticker-rare-5.webp"
import epic1 from "images/collection-1/epic/sticker-epic-1.webp"
import epic2 from "images/collection-1/epic/sticker-epic-2.webp"
import epic3 from "images/collection-1/epic/sticker-epic-3.webp"
import epic4 from "images/collection-1/epic/sticker-epic-4.webp"
import legendary1 from "images/collection-1/legendary/sticker-legendary-1.gif"
import legendary2 from "images/collection-1/legendary/sticker-legendary-2.gif"
import legendary3 from "images/collection-1/legendary/sticker-legendary-3.gif"

import common1_2 from "images/collection-2/common/sticker-common-1.webp"
import common2_2 from "images/collection-2/common/sticker-common-2.webp"
import common3_2 from "images/collection-2/common/sticker-common-3.webp"
import common4_2 from "images/collection-2/common/sticker-common-4.webp"
import common5_2 from "images/collection-2/common/sticker-common-5.webp"
import common6_2 from "images/collection-2/common/sticker-common-6.webp"
import rare1_2 from "images/collection-2/rare/sticker-rare-1.webp"
import rare2_2 from "images/collection-2/rare/sticker-rare-2.webp"
import rare3_2 from "images/collection-2/rare/sticker-rare-3.webp"
import rare4_2 from "images/collection-2/rare/sticker-rare-4.webp"
import rare5_2 from "images/collection-2/rare/sticker-rare-5.webp"
import epic1_2 from "images/collection-2/epic/sticker-epic-1.webp"
import epic2_2 from "images/collection-2/epic/sticker-epic-2.webp"
import epic3_2 from "images/collection-2/epic/sticker-epic-3.webp"
import epic4_2 from "images/collection-2/epic/sticker-epic-4.webp"
import legendary1_2 from "images/collection-2/legendary/sticker-legendary-1.webp"
import legendary2_2 from "images/collection-2/legendary/sticker-legendary-2.webp"
import legendary3_2 from "images/collection-2/legendary/sticker-legendary-3.webp"
import special1_2 from "images/collection-2/special/sticker-special-1.webp"
import special2_2 from "images/collection-2/special/sticker-special-2.webp"
import special3_2 from "images/collection-2/special/sticker-special-3.webp"
import special4_2 from "images/collection-2/special/sticker-special-4.webp"
import special5_2 from "images/collection-2/special/sticker-special-5.webp"

const STICKERS_IMAGE_COLLECTION_1: Record<number, string> = {
    217: common1,
    218: common2,
    219: common3,
    220: common4,
    221: common5,
    222: common6,
    223: rare1,
    224: rare2,
    225: rare3,
    226: rare4,
    227: rare5,
    228: epic1,
    229: epic2,
    230: epic3,
    231: epic4,
    232: legendary1,
    233: legendary2,
    234: legendary3
};

const STICKERS_IMAGE_COLLECTION_2: Record<number, string> = {
    235: common1_2,
    236: common2_2,
    237: common3_2,
    238: common4_2,
    239: common5_2,
    240: common6_2,
    241: rare1_2,
    242: rare2_2,
    243: rare3_2,
    244: rare4_2,
    245: rare5_2,
    246: epic1_2,
    247: epic2_2,
    248: epic3_2,
    249: epic4_2,
    250: legendary1_2,
    251: legendary2_2,
    252: legendary3_2,
    253: special1_2,
    254: special2_2,
    255: special3_2,
    256: special4_2,
    257: special5_2,
};

const STICKER_IMAGE_COLLECTIONS: Record<number, Record<number, string>> = {
    7: STICKERS_IMAGE_COLLECTION_1,
    9: STICKERS_IMAGE_COLLECTION_2
}

export { STICKERS_IMAGE_COLLECTION_1 }
export { STICKERS_IMAGE_COLLECTION_2 }
export { STICKER_IMAGE_COLLECTIONS }